@import '../../styles.scss';
.card {
    border-color: #edf1fa;

    &-header, &-body {
        padding-left: 1.5em;
        padding-right: 1.5em;
    }

    &-header {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        background-color: inherit;
        border-color: inherit;
        margin: 0.5em 0;

        .title {
            font-weight: bolder;
            margin: 0;
        }

        .btn-edit {
            @extend .btn-primary;
            @extend .btn-small;
            height: 30.8px;
            width: 75px;
        }
    }

    .btn-add {
        margin-left: 0!important;
    }    
}