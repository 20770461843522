@import 'bootstrap/dist/css/bootstrap.css';
$primary: #0099dd;
$success: #92d343;
$secondary-text: #9a9a9c;

body{
    height: 100vh;
}
.ordering {
    font-family: Arial,sans-serif!important;
    background-color: #eeeeee;
    height: 100vh;
    display: flex;
    flex-flow: column;
    overflow-x: hidden;

    .text-success {
        color:#22a82d!important;
    }
    .item-description{
        margin-bottom: 1rem;
        a {
            text-decoration: none;
        }
    }
    .card {
        &-title {
            font-weight: bold;
        }
        &.header {
            text-align: center;
            .card-text {
                font-weight: bold;
            }
        }
        &.group {
            margin-bottom: 1.5em;
            padding: 1.5em;
            .card {
                &-title {
                    margin-bottom: 1.5em;
                }
                &-body {
                    padding: 1em;
                    padding-right: 0;
                }
            }
        }
        &.footer {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .content {
        display: flex;
        flex-flow: column;
        flex: 1;
        overflow-y: auto;

        .products, .cart {
            padding-top: 1.5em;
        }

        .contacts {
            padding-bottom: 100px;
            background-color: #fff;
            color: $secondary-text;
            width: 100%;
            flex: 1;
            .form-group {
                label {
                    margin-bottom: 0.5rem;
                }
            }
        }
    }
    .order-placed {
        background-color: #fff;
        z-index: 1;
        .orders {
            border-top: 4px;
            border-right: 0;
            border-left: 0;
            border-bottom: 4px;
            border-style: dotted;
        }
    }

    .amount {
        &-field {
            height: 1.75rem;
        }
        &-button {
            color: $primary
        }
    }

    .footer-button {
        &-primary {
            width: 100%;
            border: none;
            background-color: $primary;
            font-size: medium;
            margin-bottom: 0.5em;
        }

        &-secondary {
            width: 100%;
            font-size: small;
        }
    }
    
    .order-summary {
        color: #fff;
        background-color: #22a82d;
        text-align: center;
        font-weight: bolder;
        z-index: 1;
        padding: 1.25rem 0;
    }

    .browse {
        &-button {
            background-color: #f1f4f9;
            border-radius: 100px;
            padding: 0.375rem 1em;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &-title {
            color: #0099dd
        }
        &.modal {
            .modal-dialog {
                .modal-content {
                    padding-bottom: 5px 0;
                    border-radius: 1.75rem;
                    text-align: left;
                }
            }
            &.show .modal-dialog {
                transform: none;
            }
            .modal-body a {
                display: block;
                font-weight: bold;
                text-transform: uppercase;
            }
        }
    }
}
.prepend-icon {
    min-width: 90px;
    padding-left: 40px;
    background: no-repeat 10px 10px;
    background-size: 20px;
    &::after {
        text-indent: 10px;
        margin-left: auto!important;
    }
}

.mobile-number {
    .dropdown-menu-content {
        height: 200px;
    }
    button.prepend-icon {
        min-height      : 100%;
        border-color    : #ced4da;

        span {
            margin-right: 10px;
        }
    }
    input.prepend-icon {
        background-image: url("../../../assets/images/search.svg")
    }
}
