@import '../../styles.scss';
.merchant {
    .item {
        display: flex;
        flex-flow: row;
        border-bottom: solid 1px #e1e8f0;
        box-shadow: none;
        padding: 1em 0;

        &-details {
            flex: 1;
            margin: 0 1em;
        }

        &-name {
            font-weight: bold;
        }

        &-description {
            margin-bottom: 1rem;
            a {
                text-decoration: none;
            }
        }
    
        &-actions {
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            min-width: 134px;

            .btn-edit {
                @extend .btn-primary;
                @extend .btn-small;
                width: 100%;
                margin-bottom: 1em;
            }
            .form-check-input:checked {
                background-color: $success;
                border-color: $success;
                + .form-check-label {
                    color: $success;
                }
            }
            .form-check-label {
                color: $secondary-text;
            }
        }
    }
}