@import 'bootstrap/dist/css/bootstrap.css';
$primary: #0099dd;
.login {
    font-family: Arial,sans-serif!important;
    display: flex;
    min-height: 100vh;
    /* Location of the image */
    /* background-image: url(../../../assets/images/bg_merchant_login.jpeg); */
    
    /* Background image is centered vertically and horizontally at all times */
    background-position: center center;
    
    /* Background image doesn’t tile */
    background-repeat: no-repeat;
    
    /* Background image is fixed in the viewport so that it doesn’t move when
    the content’s height is greater than the image’s height */
    background-attachment: fixed;
    
    /* This is what makes the background image rescale based
    on the container’s size */
    background-size: cover;
    
    /* Set a background color that will be displayed
    while the background image is loading */
    background-color: #464646;
    
    .form-group {
        margin-bottom: 1.5rem
    }
    
    .form-inline {
        padding: 0 0.5rem;
        margin-bottom: 1.5rem;
        align-items: center;
        display: flex;
        justify-content: space-between;
    }
    
    .link, .highlight, .btn-link {
        color: $primary!important;
    }
    
    .btn {
        display: inline-block;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        border-radius: 0.25rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    
        &-primary {
            @extend .btn;
            color: #fff;
            background-color: $primary!important;
            border-color: $primary!important;
        }
        &-login {
            @extend .btn;
            @extend .btn-primary;
            width: 100%;
        }
        &-link {
            @extend .btn;
            text-decoration: none!important;
        }
    }
    
    .card {
        margin: auto;
        padding: 1.5em 2em;
        border: 0!important;
    }
    
    .logo {
        width: 100%;
        max-width: 350px;
        margin-bottom: 0.5em;
        padding: 0 2em;
    }
    
    .subtitle {
        color: #8e8e91;
        margin-top: 1em;
        margin-bottom: 2em;
        text-align: center;
    }
    
    .custom-control-label {
        margin-left: 1em;
    }
}