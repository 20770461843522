@import 'bootstrap/dist/css/bootstrap.css';
$primary: #0099dd;
$secondary-text: #9a9a9c;
.order-placed {
    font-family: Arial, sans-serif !important;
    height: 100vh;
    display: flex;
    flex-flow: column;
    text-align: center;
    align-items: center;
    align-content: center;

    .card {
        border: none;
        &-title {
            font-weight: bold;
        }
        &.header {
            text-align: center;
            .card-text {
                font-weight: bold;
            }
        }
    }

    .content {
        flex: 1;
        display: flex;
        flex-flow: column;
        text-align: center;
        justify-content: center;

        padding: 3em;
        margin-bottom: 4em;
        color: $secondary-text;

        .status {
            color: #0d9a00;
            font-weight: bold;
        }

        .amount {
            color: black;
            font-weight: bold;
            margin-bottom: 2em;
        }
    }
    
    .pay-button {
        margin: 1em 0;
        background-color: $primary;
        color: #fff;
    }
}